import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { dateToDDMMYYYY, DDMMYYYYToDate } from '../../utils/date';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date();

export const YearMonthForm = ({ date, localeUtils, onChange, ageGroup }) => {
  const months = localeUtils.getMonths();

  // Use appropriate years for age group
  let minusYears = 100;
  switch (ageGroup) {
    case 'under5s':
      minusYears = 5;
      break;
    case 'children':
      minusYears = 17;
      break;
    default:
      minusYears = 100;
      break;
  }
  const fromYear = new Date(currentYear - minusYears, 0);

  const years = [];
  for (let i = fromYear.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()} className="border">
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()} className="border">
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

export const DateInput = (props) => {
  const [month, setMonth] = useState(new Date());

  const handleYearMonthChange = (newMonth) => {
    setMonth(newMonth);
  };

  return (
    <Form.Group className={props.groupClassName} controlId={props.controlId || props.name}>
      {props.label && (
        <Form.Label className={`${props.labelClassName} ${props.hideLabel ? 'invisible' : ''}`}>
          {props.label}
        </Form.Label>
      )}

      <div className={`${props.invalid ? 'is-invalid' : ''}`}>
        <DayPickerInput
          inputProps={{
            className: `${props.className} ${props.invalid ? 'is-invalid' : ''}`,
            name: props.name,
            placeholder: props.placeholder,
            ...props.attrs,
          }}
          onDayChange={(day) => props.onChange(props.name, dateToDDMMYYYY(day))}
          parseDate={DDMMYYYYToDate}
          formatDate={dateToDDMMYYYY}
          value={props.value}
          fromMonth={fromMonth}
          toMonth={toMonth}
          keepFocus={false}
          dayPickerProps={
            props.dob
              ? {
                  captionElement: ({ date, localeUtils }) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                      ageGroup={props.ageGroup || ''}
                    />
                  ),
                  disabledDays: [{ after: new Date() }],
                  month,
                }
              : undefined
          }
        />
      </div>

      {props.invalid && <Form.Control.Feedback type="invalid">{props.validationMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
};

DateInput.defaultProps = {
  groupClassName: '',
  labelClassName: '',
  className: 'col form-control',
  onChange: () => {},
  attrs: {},
  dob: false,
};

DateInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
  dob: PropTypes.bool,
};

export default DateInput;
