import { useState } from 'react';

export const useLocalFormState = (validator, initialValues = {}, initialErrors = {}) => {
  const [errors, setErrors] = useState({ ...initialErrors });
  const [values, setValues] = useState({ ...initialValues });

  function isValid() {
    const _errors = typeof validator === 'function' ? validator(values) : {};
    return Object.keys(_errors).length === 0;
  }

  // Pass an object to set multiple form properties, or pass key and value to set one property
  function onChange(key, value) {
    if (typeof key === 'object') {
      setValues({ ...values, ...key });
    } else {
      setValues({ ...values, [key]: value });
    }
  }

  function validate() {
    const _errors = typeof validator === 'function' ? validator(values) : {};
    setErrors(_errors);
    return _errors;
  }

  return {
    values,
    onChange,
    validate,
    isValid,
    errors,
  };
};

export default useLocalFormState;
