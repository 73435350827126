import { GET_INIT_DATA } from '../../init/action-types';
import { channelsSelector } from '../../data/channels/selectors';
import { regionsSelector, channelRegionIdsSelector } from '../../data/regions/selectors';
import { getIsFetching } from '../../requests/selectors';
import {
  getRegionsIsLoadingSelector,
  getRegionsIsLoadedSelector,
  getRegionsHasFailedSelector,
} from '../../data/regions/selectors';

import { dateToDDMMYYYY } from '../../../utils/date';
import { getChannelById } from '../../data/channels/utils';
import { getRegionsByChannelId, getRegionById } from '../../data/regions/utils';
import { sortBasedOnProp } from '../../../utils/utils';

export const channelIdSelector = (state) => state.search.form.channelId;
export const regionIdSelector = (state) => state.search.form.regionId;
export const arrivalDateSelector = (state) => state.search.form.arrivalDate;
export const durationSelector = (state) => state.search.form.duration;
export const monthSelector = (state) => state.search.form.month;
export const adultsSelector = (state) => state.search.form.adults;
export const childrenSelector = (state) => state.search.form.children;
export const under5sSelector = (state) => state.search.form.under5s;
export const cottageDatesSelector = (state) => state.search.form.cottageDates;

export const partySizeSelector = (state) => {
  return state.search.form.adults + state.search.form.children + state.search.form.under5s;
};

export const currentCottageDatesSelector = (state) => {
  const cottageDatesFound = cottageDatesSelector(state);
  if (cottageDatesFound) return cottageDatesFound[regionIdSelector(state)];
};
export const cottageTTLSelector = (state) => {
  const cottageDatesFound = cottageDatesSelector(state);
  if (cottageDatesFound) return cottageDatesFound.ttl;
};
export const petFriendlySelector = (state) => {
  return channelIdSelector(state) === 'eurocaravan' ? undefined : state.search.form.petFriendly;
};

export const paramsSelector = (state) => ({
  channelId: channelIdSelector(state),
  regionId: regionIdSelector(state),
  arrivalDate: arrivalDateSelector(state),
  duration: durationSelector(state),
  adults: adultsSelector(state),
  children: childrenSelector(state),
  under5s: under5sSelector(state),
  petFriendly: petFriendlySelector(state),
});

export const requestParamsSelector = (state) => ({
  channel: channelIdSelector(state),
  region: regionIdSelector(state),
  date: dateToDDMMYYYY(new Date(arrivalDateSelector(state))),
  partysize: adultsSelector(state) + childrenSelector(state) + under5sSelector(state),
  nights: durationSelector(state),
  adults: adultsSelector(state),
  children: childrenSelector(state),
  infants: under5sSelector(state),
  dogs: petFriendlySelector(state) ? 'y' : '',
});

export const channelsLoadingSelector = (state) => getIsFetching(state, GET_INIT_DATA);

export const regionsLoadingSelector = (state) => getRegionsIsLoadingSelector(state, channelIdSelector(state));

export const regionsNeedToBeLoadedSelector = (state) => {
  const _channelId = channelIdSelector(state);

  return (
    _channelId &&
    !getRegionsIsLoadingSelector(state, _channelId) &&
    !getRegionsIsLoadedSelector(state, _channelId) &&
    !getRegionsHasFailedSelector(state, _channelId)
  );
};

export const currentChannelSelector = (state) => {
  return getChannelById(channelsSelector(state), channelIdSelector(state));
};

const EUROCARAVAN_SORT_ORDER = [
  'France: North',
  'France: Central',
  'France: South & Corsica',
  'Spain',
  'Italy: North',
  'Italy: Central & Sardinia',
  'Croatia',
  'Netherlands & Luxembourg',
  'Germany',
  'Austria & Switzerland',
  'Portugal',
  'Slovenia',
];

export const currentRegionsSelector = (state, newChannel = false) => {
  const channelToUse = newChannel || channelIdSelector(state);
  const regionsByChannelId = getRegionsByChannelId(
    regionsSelector(state),
    channelRegionIdsSelector(state),
    channelToUse,
  );
  if (channelToUse === 'ukcaravan') {
    regionsByChannelId.unshift({
      id: 'all-regions',
      name: 'All of the UK',
      dogs: 'Y',
    });
  }
  if (channelToUse === 'eurocaravan') {
    return sortBasedOnProp(regionsByChannelId, EUROCARAVAN_SORT_ORDER, 'name');
  }
  return regionsByChannelId?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const currentRegionSelector = (state) => {
  return getRegionById(regionsSelector(state), regionIdSelector(state));
};
