import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Home from './pages/Home/container';
import { contentRoutes } from '../config';

// Lazy load everything except home, we want to optimize LCP
const Booking = lazy(() => import('./pages/Booking/Booking'));
const FetchContent = lazy(() => import('./pages/Content/FetchContent'));
const TermsConditions = lazy(() => import('./pages/Content/TermsConditions'));
const TravelGuide = lazy(() => import('./pages/Content/TravelGuide'));
const Parks = lazy(() => import('./pages/Browse/Parks'));
const OperatorRegions = lazy(() => import('./pages/Browse/OperatorRegions'));
const Regions = lazy(() => import('./pages/Browse/Regions'));
const TravelGuideHome = lazy(() => import('./pages/TravelGuideHome/TravelGuideHome'));
const Reset = lazy(() => import('./pages/Reset'));
const AllDatesSearchResults = lazy(() => import('./pages/SearchResults/AllDatesSearchResults'));
const AllRegionsSearchResults = lazy(() => import('./pages/SearchResults/AllRegionsSearchResult'));
const Help = lazy(() => import('./pages/Help/Help'));
const NotFound = lazy(() => import('./pages/404'));
const ChangePassword = lazy(() => import('./pages/ChangePassword/ChangePassword'));
const Login = lazy(() => import('./pages/Login/container'));
const SearchResults = lazy(() => import('./pages/SearchResults/SearchResults'));
const MyAccount = lazy(() =>
  import('./pages/MyAccount/MyAccount').then((module) => {
    return { default: module.MyAccount };
  }),
);
const ParkPage = lazy(() =>
  import('./pages/HolidayPark/ParkPage').then((module) => {
    return { default: module.ParkPage };
  }),
);

export const Routes = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    {/* Search & Booking */}
    <Route path="/results/all-regions" component={AllRegionsSearchResults} />
    <Route path="/results/all-dates" component={AllDatesSearchResults} />
    <Route path="/results" component={SearchResults} />
    <Route path="/boat/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
    <Route path="/boat-result/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
    <Route path="/park-result/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
    <Route path="/booking" component={Booking} exact />
    <Route path="/booking/:step" component={Booking} />
    {/* Browsing */}
    <Route path="/channel/:channelCode" strict component={Regions} />
    <Route path="/region/:channelCode" strict component={Regions} />
    {/* This has to go before dog-friendly/channelCode */}
    <Redirect exact from="/dog-friendly/uk" to="/dog-friendly/ukcaravan" />
    <Route path="/dog-friendly/:channelCode" strict component={Regions} />
    <Route path="/:operatorId/region/:channelCode" strict component={OperatorRegions} />
    <Route path="/parks/:channelId/:regionId/:slug?" component={Parks} />
    <Route path="/holidays/:channelId/:regionId/:slug?" component={Parks} exact />
    <Route path="/holidays/:channelId/:regionId/:parkId/:slug?" component={ParkPage} />
    {/* <-- Search & Booking */}
    <Route path="/area/:channelId/:regionId/:townId/:slug?" component={Parks} /> {/* <-- Town pages */}
    <Route path="/boating-holidays/:channelId/:regionId/:slug?" component={Parks} />
    <Redirect from="/rentals" to="/region/ukcottages" />
    {/* User */}
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/login" component={Login} />
    <Route path="/my-account" component={MyAccount} />
    {/* Content */}
    <Route path="/inspiration" component={TravelGuideHome} />
    <Route path="/p/:contentType/:contentId" component={FetchContent} />
    <Route path="/a/:contentId/:keywords">
      <FetchContent contentData="age" />
    </Route>
    <Route path="/b/:contentId/:keywords">
      <FetchContent contentData="ost" />
    </Route>
    <Route path="/terms" component={TermsConditions} />
    <Route path="/help" component={Help} />
    <Route path="/travel-guide/:regionName/:regionId" component={TravelGuide} />
    {/* CMS content */}
    {contentRoutes.map((route) => (
      <Route key={route.path} path={route.path} component={() => <FetchContent post={route.url} />} />
    ))}
    {/* Dev tools */}
    <Route path="/reset" component={Reset} />
    <Route path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
