import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../text';
import { useIsIOS } from '../../hooks/useIsIOS';
import { useSubmitAndCloseDropdown } from '../../hooks/useSubmitAndCloseDropdown';
import { getRegions } from '../../redux/data/regions/actions';
import { selectRegion } from '../../redux/search/form/actions';
import {
  channelIdSelector,
  currentRegionsSelector,
  regionIdSelector,
  regionsLoadingSelector,
  regionsNeedToBeLoadedSelector,
} from '../../redux/search/form/selectors';
import { regionsSelector } from '../../redux/data/regions/selectors';

export const RegionSelect = ({ textStyle }) => {
  const dispatch = useDispatch();

  const regionNames = useSelector(regionsSelector);
  const selectedChannelId = useSelector(channelIdSelector);
  const regionsLoading = useSelector(regionsLoadingSelector);
  const regionsNeedToBeLoaded = useSelector(regionsNeedToBeLoadedSelector);
  const regionsAreLoading = regionsNeedToBeLoaded || regionsLoading;
  const selectedRegionId = useSelector(regionIdSelector);

  const [regionName, setRegionName] = useState('');
  const { submitAndCloseDropdown } = useSubmitAndCloseDropdown();
  const { isIOS } = useIsIOS();

  const getRegionLabel = selectedChannelId === 'boats' ? t('search.route') : t('search.region');

  const currentRegions = useSelector(currentRegionsSelector) || [];

  const [showRegionSelect, setShowRegionSelect] = useState(false);
  const handleRegionToggle = () => setShowRegionSelect(!showRegionSelect);
  const handleRegionSelect = (event) => {
    submitAndCloseDropdown(event.target.value, selectRegion, setShowRegionSelect);
  };

  // Load regions, if required
  useEffect(() => {
    if (regionsNeedToBeLoaded) {
      getRegions(dispatch, selectedChannelId);
    }
  }, [dispatch, regionsNeedToBeLoaded, selectedChannelId]);

  useEffect(() => {
    if (!regionsLoading && selectedRegionId === undefined && currentRegions.length) {
      selectRegion(dispatch, currentRegions[0]?.id);
    }
  }, [currentRegions, dispatch, regionsLoading, selectedRegionId]);

  useEffect(() => {
    if (!selectedRegionId) setRegionName('Loading...');
    else if (selectedRegionId === 'all-regions') setRegionName('All of the UK');
    else setRegionName(regionNames[selectedRegionId]?.name);
  }, [regionNames, selectedRegionId]);

  let regionOptions;
  if (!selectedChannelId) {
    regionOptions = null;
  } else if (regionsAreLoading) {
    regionOptions = (
      <option value="2" disabled>
        {t('search.loadingRegions')}
      </option>
    );
  } else if (currentRegions) {
    regionOptions = currentRegions.map((region) => (
      <Form.Check
        key={region.id}
        id={region.id}
        type="radio"
        label={region.name}
        name="regionSelections"
        value={region.id}
        onChange={handleRegionSelect}
        disabled={!selectedChannelId}
        className="bf-radio"
        checked={selectedRegionId === region.id}
      />
    ));
  }

  return (
    <Form.Group controlId="searchbox-region">
      <Form.Label className={`text-${textStyle}`}>
        <span>{getRegionLabel}</span>
      </Form.Label>
      {isIOS ? (
        <Form.Control
          as="select"
          className="p-0 toggle-popup"
          value={regionsAreLoading ? '' : selectedRegionId}
          onChange={handleRegionSelect}
          disabled={!selectedChannelId}
          tabIndex="0"
        >
          {currentRegions.map((region) => (
            <option key={region.id} value={region.id} className="py-2 pl-3">
              {region.name}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Dropdown show={showRegionSelect} onToggle={handleRegionToggle}>
          <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown" role="button" tabIndex="0">
            <span>{regionsAreLoading ? '' : regionName}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="a-popup p-0 pt-3">{regionOptions}</Dropdown.Menu>
        </Dropdown>
      )}
    </Form.Group>
  );
};

RegionSelect.defaultProps = {
  textStyle: 'white',
};

RegionSelect.propTypes = {
  textStyle: PropTypes.string,
};
