import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import t from '../../../text';
import OfferBoxes from '../../components/HomePage/OfferBoxes';
import ScrollToTop from '../../components/ScrollToTop';

import ColumnComponent from '../../components/Common/ColumnComponent';
import Banner from '../../components/HomePage/Banner';
import Countdown from '../../components/HomePage/Countdown';
import Hero from '../../components/HomePage/Hero';

function Home({ content }) {
  const components = content.map((block, index) => {
    if (block.type === 'Countdown' && block.data.status === false) return <div key={index} />; // This shouldn't be here but getting inactive countdowns?!
    switch (block.type) {
      case 'HeroText':
        return (
          <Hero
            key={index}
            heading={block.data.heading}
            headingImage={block.data.heading_image}
            subheading={block.data.subheading}
            heroImage={block.data.hero_image}
            mobileHeroImage={block.data.mobile_hero_image}
          />
        );
      case 'Banner':
        return (
          <Banner
            key={index}
            fluid={block.data.fluid}
            bg={block.data.bg}
            text={block.data.text}
            content={block.data.content}
            url={block.data.url}
          />
        );
      case 'Countdown':
        return (
          <Countdown
            key={index}
            datetime={block.data.datetime}
            title={block.data.title}
            text={block.data.text}
            linktext={block.data.linktext}
            url={block.data.url}
            background_colour={block.data.background_colour}
            text_colour={block.data.text_colour}
          />
        );
      case 'HomeColumns':
        return (
          <Fragment key={index}>
            <ColumnComponent
              heading={block.data.heading}
              columns={block.data.columns}
              background={block.data.background_colour || 'white'}
            />
            {block.data.heading.includes('Breakfree Deals') && <OfferBoxes />}
          </Fragment>
        );
      default:
        return <div key={index} />;
    }
  });

  return (
    <>
      <Helmet>
        <title key="title">{t('home.page.title')}</title>
      </Helmet>
      <ScrollToTop />
      {components}
    </>
  );
}

export default Home;
