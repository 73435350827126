import React from 'react';
import { config } from '../config';
import { Link } from 'react-router-dom';
import A from './components/A';
import { parkMatcher, shouldItPluralize, forceSingular, accommodationType } from './utils/utils';

// Please keep entries in alphabetical key order - thanks!
export const text = {
  'booking.booking.confirmTerms': (btnText, termsAndConditionsUrl, privacyPolicyUrl) => (
    <>
      By clicking on {btnText}, you agree you have read and accept our{' '}
      <A href={termsAndConditionsUrl}>Terms and Conditions</A> and <A href={privacyPolicyUrl}>Privacy Policy</A>.
    </>
  ),
  'booking.booking.continueToStepX': (x) => `Continue to step ${x}`,
  'booking.booking.errorLoadingData': 'There was an error while loading the booking data. Please try again.',
  'booking.booking.failed': 'Booking failed',
  'booking.booking.loading': 'Loading booking data, please wait...',
  'booking.booking.noBookingInProgress': () => (
    <>
      You do not currently have a booking in progress. Please use our <Link to="/">search form</Link> to find and book
      your perfect holiday.
    </>
  ),
  'booking.booking.stepXofY': (x, y) => `Step ${x} of ${y}`,
  'booking.booking.unableToBookHoliday': (prevPath) => (
    <>
      We are sorry but we were unable to book your holiday. Please return to the{' '}
      <Link to={prevPath} replace>
        park page
      </Link>{' '}
      and try again.
    </>
  ),
  'booking.breakdown.accommodationPrice': 'Accommodation Price',
  'booking.breakdown.amountPaid': 'Amount paid:',
  'booking.breakdown.amountToPayToday': 'Amount to pay today:',
  'booking.breakdown.leftToPayLater': (amount) => `Left to pay later: £${amount}`,
  'booking.breakdown.currentPriceBreakdown': 'Current Price Breakdown',
  'booking.breakdown.enterAmountToPay': 'Enter amount to pay (£)',
  'booking.breakdown.outstandingAmount': (dateStr, amount) => `Outstanding amount to pay before ${dateStr}: £${amount}`,
  'booking.breakdown.priceBreakdown': 'Price Breakdown',
  'booking.breakdown.introText': 'No surprises! Here is a breakdown:',
  'booking.breakdown.totalCost': 'Total cost:',
  'booking.confirmation.bookAttractions': 'Find and Book Attractions',
  'booking.confirmation.bookAgain': 'Book Another Holiday',
  'booking.confirmation.bookAnotherBreak': 'Book another break',
  'booking.confirmation.bookAnotherBreakText':
    'We have lots of great holidays added every week, so if you fancy searching use the link below.',
  'booking.confirmation.holidayBooked': 'Your Holiday Booking Has Been Confirmed!',
  'booking.confirmation.youAreGoingTo': (operatorName, parkName) => (
    <>
      Congratulations, you’re going on holiday to{' '}
      <b>
        {operatorName} - {parkName}
      </b>
    </>
  ),
  'booking.confirmation.planAhead': 'Now it’s time to plan ahead!',
  'booking.confirmation.planAheadText':
    'We have partnered with attraction booking site Viator to offer you some exciting attractions to help make your holiday one to remember. From magical theme parks to exciting walking tours and everything in between, Breakfree and Viator have you covered. Alternatively, you can book another break or check the status of all holidays you have booked with Breakfree and update your account details.',
  'booking.confirmation.myAccount': `My ${config['global.brandName']}`,
  'booking.confirmation.myAccountText': `In your My ${config['global.brandName']} account you can check the status of your booking and update your details.`,
  'booking.confirmation.noBookingConfirmation': 'There is no booking confirmation data to show at this time.',
  'booking.confirmation.paymentReceived': 'Your payment has been received',
  'booking.confirmation.thankYouFor': (isNewBooking) => `Thank you for ${isNewBooking ? 'booking' : 'your payment'}`,
  'booking.confirmation.visitMyAccount': 'Visit My Account',
  'booking.confirmation.yourBookingReferenceIs': 'Your holiday booking reference is:',
  'booking.details.errorLoading': 'There was an error while loading your details. Please try again.',
  'booking.details.errorSaving': 'There was an error while saving your details. Please try again.',
  'booking.extras.errorLoading':
    'Good news, there are no extra charges for this holiday, so you can click the button below and proceed to completing your booking now!',
  'booking.extras.errorSaving': 'There was an error while saving your selected extras. Please try again.',
  'booking.party.bookYourHoliday': 'Book your holiday',
  'booking.party.errorLoading': 'There was an error while loading your party details. Please try again.',
  'booking.party.errorSaving': 'There was an error while saving your party details. Please try again.',
  'booking.party.yourPartyDetails': 'Your Party Details',
  'booking.payment.AdditionalPaymentSecurity': 'Additional payment security',
  'booking.payment.cardNumber': 'Card number',
  'booking.payment.cardSecurityNumber': 'Card security number',
  'booking.payment.creditOrDebitCard': 'Credit or Debit Card',
  'booking.payment.error3DSecure': 'There was an error during 3D Secure validation. Please try again.',
  'booking.payment.errorAccessingServer':
    'There was an error while accessing the server. Please use the button below to retry (you will not be charged twice).',
  'booking.payment.errorLoading':
    'There was an error while loading required payment information for your booking. Please try again.',
  'booking.payment.errorProcessingPayment':
    'There was an error processing your previous payment. Please try again. No payment has been taken from you at this time.',
  'booking.payment.errorSubmittingForm': 'There was an error while submitting the form. Please try again.',
  'booking.payment.expirationDate': 'Expiration date',
  'booking.payment.initialisingCardForm': 'Initialising card payment form, please wait...',
  'booking.payment.initialisingPaypal': 'Initialising PayPal, please wait...',
  'booking.payment.loading': 'Loading payment form data, please wait...',
  'booking.payment.makeYourPayment': 'Make your payment',
  'booking.payment.payit': 'PayIt',
  'booking.payment.paypal': 'PayPal',
  'booking.payment.processing': 'Processing payment, please wait...',
  'booking.summary.confirmationOf': (isNewBooking) =>
    `Confirmation of your ${isNewBooking ? 'holiday booking' : 'payment'} has been emailed to you.`,
  'booking.summary.checkInAt': (dateStr) => (
    <>
      Check in: <span className="font-weight-normal">{dateStr}</span>
    </>
  ),
  'booking.summary.checkOutAt': (dateStr) => (
    <>
      Check out: <span className="font-weight-normal">{dateStr}</span>
    </>
  ),
  'booking.summary.printBookingRef': 'Print booking reference',
  'booking.summary.xNightStay': (duration) => `${duration} night stay`,
  'browse.operatorRegions.title': (channelName, operatorName) => `${channelName} from ${operatorName}`,
  'browse.operators.errorLoading': 'There was an error while loading the operators list. Please try again.',
  'browse.regions.errorLoading': 'There was an error while loading the regions list. Please try again.',
  'browse.regions.title': (isPetFriendly, channelName) =>
    `${isPetFriendly ? `Dog Friendly ${channelName}` : `Browse ${channelName} by Region`}`,
  'browse.parks.loadingParks': 'Loading, please wait...',
  'browse.parks.title': (regionName, channel) => `Browse ${regionName} by ${accommodationType(channel)}`,
  'buttons.back': 'Back',
  'buttons.book': (price) => `Book from £${price}`,
  'buttons.continue': 'Continue',
  'buttons.createAnAccount': 'Create an Account',
  'buttons.done': 'Done',
  'buttons.login': 'Login',
  'buttons.map': 'Map',
  'buttons.readMore': 'Read more',
  'buttons.register': 'Register',
  'buttons.resetPassword': 'Reset password',
  'buttons.retry': 'Retry',
  'buttons.submit': 'Submit',
  'buttons.update': 'Update',
  'buttons.viewX': (x) => `View ${x}`,
  'feefo.logo': 'Feefo logo',
  'feefo.ourCustomerFeefoRating': 'Our customer Feefo rating',
  'footer.cookieConsent.text':
    'Our website uses cookies to give you the best experience. By using our website you agree with our use of cookies.',
  'footer.nav.companyName': `${config['global.brandName']} Holidays`,
  'footer.nav.findAHoliday': 'Find a Holiday',
  'footer.nav.holidayType': 'Holiday Type',
  'footer.nav.information': 'Information',
  'footer.nav.tagline': `${config['global.brandName']} Holidays are registered in England No. 05753643 | Copyright © ${config['global.brandName']}. All rights reserved.`,
  'footer.newsletter.apply': 'apply',
  'footer.newsletter.emailAddress': 'Email address',
  'footer.newsletter.firstName': 'First Name',
  'footer.newsletter.invalidFirstName': 'Please add your first name.',
  'footer.newsletter.invalidEmail': 'Please choose a valid email address.',
  'footer.newsletter.subscribe': 'Subscribe',
  'footer.newsletter.successShort': 'Thank you for subscribing!',
  'footer.newsletter.success':
    'Thank you for subscribing! You will now receive the latest holiday offers straight to your inbox',
  'footer.newsletter.termsAndConditions': 'Terms & conditions',
  'footer.newsletter.title':
    'Sign up to our newsletter for all our latest and exclusive holiday offers delivered straight to your inbox.',
  'footer.reviews.averageCustomerRating': 'Average Customer Rating:',
  'footer.reviews.bookWithConfidence': 'Book with Confidence',
  'footer.reviews.connectWithUs': 'Connect with Us',
  'footer.reviews.independentServiceRating': 'Independent Service Rating Based on 236,874 verified reviews.',
  'footer.reviews.readAllReviews': 'Read all reviews',
  'footer.reviews.recentComments': 'Some recent comments from our customers',
  'footer.trust.greatCustomerService': 'Great Customer Service',
  'footer.trust.greatCustomerServiceText': 'Thousands of customer reviews independently collected by Feefo.',
  'footer.trust.securePaymentOptions': 'Secure Payment Options',
  'footer.trust.securePaymentOptionsText': "We partner with trusted brands to ensure our customers' security.",
  'footer.trust.title': `Breakfree - A Name You Can Trust`,
  'footer.trust.title2': 'Over 250,000 satisfied customers every year...',
  'footer.trust.whyChooseUs': 'Why choose Breakfree?',
  'footer.trust.whyChooseUsText': 'Backed by our Customer Charter and Price Promise',
  'forms.address.address': 'Address',
  'forms.address.blankAddress': 'Please enter your address',
  'forms.address.blankCounty': 'Please enter your county',
  'forms.address.blankTown': 'Please enter your town',
  'forms.address.country': 'Country',
  'forms.address.county': 'County',
  'forms.user.invalidPostcode': 'Please enter a valid postcode',
  'forms.address.invalidPostcode': 'Please enter a valid postcode',
  'forms.address.postcode': 'Postcode',
  'forms.address.selectCountry': 'Select country',
  'forms.address.town': 'Town',
  'forms.booking.blankDiscountCode': 'Please enter discount code',
  'forms.cardPayment.bookingSafeSecure': 'Your booking is secure and safe. Please fill in all fields.',
  'forms.cardPayment.cardNumber': 'Card Number',
  'forms.cardPayment.day': 'Day',
  'forms.cardPayment.expiryDate': 'Expiry Date',
  'forms.cardPayment.expiryDateDay': 'Expiry Date (day)',
  'forms.cardPayment.expiryDateMonth': 'Expiry Date (month)',
  'forms.cardPayment.expiryMonth': 'Expiry month',
  'forms.cardPayment.month': 'Month',
  'forms.cardPayment.nameOnCard': 'Name on Card',
  'forms.cardPayment.securityCode': 'Security Code (CV2)',
  'forms.changePassword.blankPassword': 'Please enter a password',
  'forms.changePassword.blankResetCode': 'Please enter the reset code that was sent to you',
  'forms.changePassword.confirmNewPassword': 'Confirm new password',
  'forms.changePassword.confirmPassword': 'Please confirm your password',
  'forms.changePassword.invalidResetCode': 'Invalid reset code',
  'forms.changePassword.newPassword': 'New password',
  'forms.changePassword.passwordMismatch': 'Passwords do not match',
  'forms.changePassword.resetCode': 'Reset code',
  'forms.contactPrefs.title': 'Your Contact Preferences',
  'forms.contactPrefs.emailNewspaperOffers':
    'If you booked via a newspaper promotion, would you like to receive news and offers by email from your participating newspaper? (You can unsubscribe at any time).',
  'forms.contactPrefs.offers':
    'Please tick the boxes if you are happy for Breakfree Holidays to contact you with the latest holiday offers by:',
  'forms.contactPrefs.emailOpt': 'Email',
  'forms.contactPrefs.smsOpt': 'Phone',
  'forms.contactPrefs.postOpt': 'Post',
  'forms.contactPrefs.stayUpdated': 'Stay updated',
  'forms.forms.no': 'No',
  'forms.forms.noValueSelected': 'Please select a value',
  'forms.forms.yes': 'Yes',
  'forms.extras.introText': 'Make sure your holiday is perfect, add the extras you need:',
  'forms.login.blankPassword': 'Please enter your password',
  'forms.login.forgotYourPassword': 'Forgot your password?',
  'forms.login.invalidPassword': 'Please enter a valid password',
  'forms.login.notAMember': "No, I'm not a member",
  'forms.party.adults': 'Adults (18+)',
  'forms.party.age': 'Age',
  'forms.party.areYouGoing': 'Are you going on this holiday?',
  'forms.party.children': 'Children (5-17)',
  'forms.party.checkPartySizeDetails':
    'Please check your party size details are correct to get the best search results',
  'forms.party.checkYourPartySize': 'Check your party size',
  'forms.party.dob': 'Date of birth',
  'forms.party.infants': 'Under 5s',
  'forms.party.initial': 'Initial',
  'forms.party.noIAmOnlyMakingTheBooking': 'No, I am only making the booking',
  'forms.party.pleaseFillInDetails': 'Please fill in the details of your holiday party below.',
  'forms.party.surname': 'Surname',
  'forms.party.title': 'Title',
  'forms.paymentOptions.depositPayment': (amount) => `Deposit Payment: £${amount}`,
  'forms.paymentOptions.depositUnavailable':
    'Paying a deposit is not available for this holiday. You must pay in full.',
  'forms.paymentOptions.fullPayment': (amount) => `Full Payment: £${amount}`,
  'forms.paymentOptions.introText': 'Pay in full or put down a deposit to secure your booking',
  'forms.paymentOptions.outstandingAmountToPay': (dateStr, amount) =>
    `Outstanding amount to pay before ${dateStr}: £${amount}`,
  'forms.paypal.introText': 'Please use the button below to complete your booking.',
  'forms.paypalCard.cardNumber': 'Card Number',
  'forms.paypalCard.confirmPayment': `Confirm payment`,
  'forms.paypalCard.confirmPaymentOf': (amount) => `Confirm payment of £${amount}`,
  'forms.paypalCard.differentBillingAddress': 'My billing address is different from my home address',
  'forms.paypalCard.expiryDate': 'Expiration date',
  'forms.paypalCard.nameOnCard': 'Name on Card',
  'forms.paypalCard.introText': 'Your booking is secure and safe. Please fill in all fields.',
  'forms.paypalCard.invalidAddress': 'Please enter your address',
  'forms.paypalCard.invalidCardName': 'Please enter the name on the front of your card',
  'forms.paypalCard.invalidCardNumber': 'Please enter a valid card number',
  'forms.paypalCard.invalidCountry': 'Please select a value',
  'forms.paypalCard.invalidCounty': 'Please enter your county',
  'forms.paypalCard.invalidExpiryDate': 'Please enter a valid expiration date',
  'forms.paypalCard.invalidPostcode': 'Please enter a valid postcode',
  'forms.paypalCard.invalidSecurityNumber': 'Please enter a valid security number',
  'forms.paypalCard.invalidTown': 'Please enter your town',
  'forms.paypalCard.processing': 'Processing, please wait...',
  'forms.paypalCard.securityCode': 'Card security number',
  'forms.resetPassword.introText':
    'Enter the email address associated with your account to receive a password reset email.',
  'forms.resetPassword.resetYourPassword': 'Reset Your Password',
  'forms.resetPassword.success': (isLoggedIn) =>
    `Your request was received successfully. ${
      !isLoggedIn ? "If your email address exists in our system, you'll" : 'You will'
    } receive an email with the link to reset your password. Please be sure to check your spam or junk folders.`,
  'forms.user.blankDob': 'Please enter your date of birth',
  'forms.user.blankEmail': 'Please enter your email address',
  'forms.user.blankFirstName': 'Please enter your forename',
  'forms.user.blankPassword': 'Please enter a password',
  'forms.user.blankSurname': 'Please enter your surname',
  'forms.user.day': 'Day',
  'forms.user.dob': 'Date of Birth',
  'forms.user.dobDay': 'Day of Birth',
  'forms.user.dobMonth': 'Month of Birth',
  'forms.user.dobYear': 'Year of Birth',
  'forms.user.emailAddress': 'Email address',
  'forms.user.firstName': 'Forename',
  'forms.user.invalidEmail': 'Please enter a valid email address',
  'forms.user.invalidPassword':
    'Passwords must be at least 8 characters, must contain upper and lower case letters, numbers and any of the following special characters ^$*.[]{}()?-"!@#%&/,><\':;|_~`',
  'forms.user.invalidTelephone': 'Please enter a valid contact telephone number',
  'forms.user.month': 'Month',
  'forms.user.mustBeOver18': 'You must be over 18 years of age',
  'forms.user.password': 'Password',
  'forms.user.surname': 'Surname',
  'forms.user.telephone': 'Contact Telephone Number',
  'forms.user.title': 'Title',
  'forms.user.year': 'Year',
  'global.page.defaultMetaDesc':
    'Great deals on a huge range of holiday parks, lodges, boating holiday and more. Book online today for exclusive offers!',
  'global.errorLoading': 'There was an error while loading. Please try again.',
  'global.errorSaving': 'There was an error while saving. Please try again.',
  'global.loading': 'Loading...',
  'global.pleaseWait': 'Please wait...',
  'global.socialMedia.facebook': 'Facebook',
  'global.socialMedia.instagram': 'Instagram',
  'global.socialMedia.twitter': 'Twitter',
  'global.socialMedia.youtube': 'Youtube',
  'header.blog': `${config['global.brandName']} Blog`,
  'header.cottages': 'Cottages',
  'header.ferries': 'Ferries',
  'header.hotels': 'Hotels',
  'header.inspiration': 'Inspiration',
  'header.cs.tel': '01234 123456',
  'header.cs.hours': 'Mon-Sat 9am - 5pm, Sun 10am - 4pm',
  'header.login': 'Login',
  'header.loginSignUp': 'Login/Register',
  'header.logout': 'Logout',
  'header.myAccount': 'My Account',
  'header.offers': 'Offers',
  'header.signUp': 'My Account',
  'header.ukAttractions': 'UK Attractions',
  'home.page.title': 'Caravan Holidays | Cheap Holiday Park Breaks',
  'init.errorLoading':
    'We are currently experiencing technical difficulties.  We are working hard to resolve them.  Please check back later',
  'myAccount.bookings.checkIn': 'Check in',
  'myAccount.bookings.checkOut': 'Check Out',
  'myAccount.bookings.holidayBookingRef': 'Holiday Booking Ref:',
  'myAccount.bookings.nights': 'Nights',
  'myAccount.bookings.noBookings.title': "We couldn't find any bookings for you.",
  'myAccount.bookings.noBookings.text': () => (
    <>
      Check out our latest deals now by viewing our <Link to="/offers">offers page</Link>.
    </>
  ),
  'myAccount.bookings.outstandingAmount': 'Outstanding Amount',
  'myAccount.bookings.paid': 'Paid.',
  'myAccount.bookings.payHolidayBalance': 'Pay Holiday Balance',
  'myAccount.page.title': 'My Account',
  'myAccount.page.subtitle': (name) => `Welcome back, ${name}!`,
  'myAccount.preferences.title': 'Preferences Centre',
  'myAccount.preferences.updateSuccess': 'Your preferences have been updated successfully.',
  'myAccount.preferences.updateFail': 'There was an error while updating your preferences. Please try again.',
  'myAccount.menu.details': 'Your Details',
  'myAccount.menu.prefs': 'Preferences Centre',
  'myAccount.menu.changePassword': 'Change Password',
  'myAccount.menu.currentBookings': 'Current Bookings',
  'myAccount.menu.pastBookings': 'Past Bookings',
  'myAccount.password.title': 'Change your password',
  'myAccount.password.text': 'Use the form below to receive a password reset email.',
  'myAccount.yourDetails.title': 'Your Details',
  'myAccount.yourDetails.text': () => (
    <>
      You can update your details here. <strong>All fields are required</strong>.
    </>
  ),
  'operators.document.title': 'Browse Holidays by Brand',
  'operators.page.title': 'Browse Holidays by Brand',
  'park.accommodation.accommodation': 'Accommodation',
  'park.accommodation.description': 'Description',
  'park.accommodation.disabledAccess': 'Disabled Access',
  'park.accommodation.features': 'Features',
  'park.accommodation.noResults':
    'There are no results available for your chosen date. Please choose an alternative date from the list above.',
  'park.accommodation.onlyXLeft': (amount) => `ONLY ${amount} LEFT`,
  'park.accommodation.saveX': (amount) => `SAVE £${amount}`,
  'park.accommodation.wasX': (amount) => `WAS £${amount}`,
  'park.accommodation.fullPrice': 'Full Price',
  'park.accommodation.discount': 'Discount',
  'park.accommodation.sleeps': 'Sleeps',
  'park.accommodation.sleepsX': (amount) => `Sleeps ${amount}`,
  'park.accommodation.summary': (count, dateStr, duration) =>
    `${count} ${shouldItPluralize('Holiday', count)} Found - ${dateStr} for ${duration} nights`,
  'park.dates.alternativeDates': 'Alternative dates available:',
  'park.dates.dateType': (isPreferred) => `${isPreferred ? 'Preferred' : 'Alternative'} date`,
  'park.dates.fromX': (amount) => `From £${amount}`,
  'park.dates.XnightsFromY': (duration, amount) => `${duration} nights from £${amount}`,
  'park.dates.selectedDate': 'Selected date',
  'park.dates.yourPreferredDate': 'Your preferred date:',
  'park.errorLoading': (isBoat) => `the ${isBoat ? 'boating' : 'park'} information`,
  'park.info.accommodation': 'Accommodation',
  'park.info.facilities': 'Facilities',
  'park.info.importantInfo': 'Important Info',
  'park.info.loadingAccommodation': 'Loading accommodation results, please wait...',
  'park.info.loadingReviews': 'Loading reviews, please wait...',
  'park.info.metaDescription': (parkName, regionName) =>
    `Explore ${parkName} Park for yourself. Enjoy a great value holiday in one of the best resorts in ${regionName}.`,
  'park.info.reviews': 'Reviews',
  'park.loading': (isBoat) => `Loading ${isBoat ? 'boating' : ''} info, please wait...`,
  'park.parkVideo': 'Park Video',
  'park.reviews.anonymous': 'Anonymous',
  'park.reviews.noReviews':
    'There are currently no customer reviews. You can view all reviews or leave one for this holiday by following the link below.',
  'park.reviews.readAll': 'Read all reviews',
  'parks.filters.filterByFeatures': 'Filter by Features',
  'parks.filters.filterByOperator': 'Filter by Operator',
  'parks.filters.filterByPetFriendly': 'Pet Friendly',
  'parks.filters.filterByPrice': 'Price range',
  'parks.filters.noResults': 'There are no results to display.',
  'parks.filters.sortBy': 'Sort by:',
  'parks.popup.newsletter':
    'Before you go, sign up to our newsletter for regular offers and a chance to WIN A HOLIDAY worth £1000',
  'search.adults': 'Adults (18+)',
  'search.children': 'Children (5-17)',
  'search.dateNights': (dateStr, duration) => `${dateStr} - ${duration} ${shouldItPluralize('nt', duration)}`,
  'search.durationAndArrivalDate': 'When',
  'search.holidayType': 'Holiday Type',
  'search.infants': 'Under 5s',
  'search.letMeSearch': 'Let me search for different holidays',
  'search.loadingRegions': 'Loading regions...',
  'search.numberOfNights': 'Number of nights',
  'search.region': 'Region',
  'search.route': 'Route',
  'search.search': 'Search',
  'search.searchNow': 'Search now',
  'search.selectADate': 'Select a Date',
  'search.selectAnArrivalDate': 'Select an arrival date',
  'search.showMePetFriendly': 'Dog(s)',
  'search.stayingForHowLong': 'Staying for how long?',
  'search.month': 'Month',
  'search.date': 'Date',
  'search.allDates': 'All dates',
  'search.newsletterSignup': 'Sign up for newsletter',
  'search.dealSignup': 'Sign up for deals',
  'search.yourParty': 'Your Party',
  'search.xNights': (val) => `${val} ${val === 1 ? 'night' : 'nights'}`,
  'searchResults.clickMarkerForDetails': 'Click marker for details',
  'searchResults.errorLoadingResults': 'There was an error while loading the search results. Please try again.',
  'searchResults.sticky': 'Park Favourite',
  'searchResults.deposit': (amount) => `£${amount} deposit`,
  'searchResults.fromDeposit': (amount) => `from £${amount} deposit`,
  'searchResults.from': 'From',
  'searchResults.holidaysFoundOn': (dateMatch, singleResult) =>
    `${forceSingular('holiday', singleResult)} found on ${dateMatch ? 'your date' : 'alternative dates'}`,
  'searchResults.accommodationLeft': (singleResult) => `accommodation ${forceSingular('type', singleResult)} left`,
  'searchResults.loading': 'Loading...',
  'searchResults.page.title': (regionName) => `Holidays in ${regionName || 'the UK'} - ${config['global.brandName']}`,
  'searchResults.page.title.alt': 'Search results',
  'searchResults.premium': 'Recommended',
  'searchResults.starting': 'Starting...',
  'searchResults.title': (total, regionName) =>
    `${total} ${shouldItPluralize('Holiday', total)} found in ${regionName}`,
  'searchResults.viewPark': (channelId, minPrice) =>
    minPrice ? (
      <>
        BOOK FROM <strong>&pound;{minPrice}</strong>
      </>
    ) : (
      `View ${parkMatcher(channelId)}`
    ),
  'searchResults.viewAllPrices': 'View All Prices',
  'termsConditions.firstHeading': 'Holiday promotion booking terms & conditions',
  'termsConditions.firstText': `Below are the terms and conditions for ${config['global.brandName']}. If you would like to view the conditions for the Regional Newspaper promotion, click here and for the Daily Mail holiday promotion click here. The newspaper terms and conditions supplement the Standard Booking Terms and Conditions Conditions and should be read in conjunction with them. Where they conflict, the promotional terms Click to see the Standard terms and conditions. To view the the terms and conditions for any of our competition giveaways please click here.`,
  'termsConditions.secondHeading': 'General Terms & Conditions',
  'termsConditions.secondText': `These are the standard terms and conditions for booking a holiday with ${config['global.brandName']}`,
  'travelGuide.checkingForTravelGuide': 'Checking for Travel Guide...',
  'travelGuide.clickHere': (regionName) => `Click here to view our ${regionName} Travel Guide`,
};

export default text;
