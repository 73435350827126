import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const TextInput = (props) => (
  <Form.Group className={props.groupClassName} controlId={props.controlId || props.name}>
    {props.label && (
      <Form.Label className={`${props.labelClassName} ${props.hideLabel ? 'invisible' : ''}`}>{props.label}</Form.Label>
    )}

    <Form.Control
      className={`${props.className} ${props.invalid ? 'is-invalid' : ''}`}
      type={props.type}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      disabled={props.disabled}
      {...props.attrs}
    />

    {props.invalid && <Form.Control.Feedback type="invalid">{props.validationMessage}</Form.Control.Feedback>}
  </Form.Group>
);

TextInput.defaultProps = {
  groupClassName: '',
  labelClassName: '',
  className: 'col',
  type: 'text',
  attrs: {},
  value: '',
};

TextInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
};

export default TextInput;
