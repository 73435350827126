import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE, CANCELLED } from './constants';

const getRequestConfig = (method, url, params, cancelTokenSource) => {
  if (['PUT', 'POST', 'DELETE', 'PATCH'].includes(method.toUpperCase())) {
    const data = new FormData();
    Object.keys(params).forEach((key) => {
      data.set(key, params[key]);
    });
    return {
      method,
      headers: { 'Content-Type': 'multipart/form-data' },
      url,
      data,
      cancelToken: cancelTokenSource.token,
    };
  }
  return {
    method,
    url,
    params,
    cancelToken: cancelTokenSource.token,
  };
};

const handleSuccess = (response, dispatch, actionType, params, requestId) => {
  // Catch server errors that return 200 code and treat them as failures
  if (response && response.data && response.data.success === 0) {
    throw response;
  }

  const action = {
    type: actionType + SUCCESS,
    payload: {
      params,
      requestId,
      response,
    },
  };
  dispatch(action);
  return action;
};

const handleFailure = (response, dispatch, actionType, params, requestId, throwOnError) => {
  if (axios.isCancel(response)) return; // Do not throw failure actions for cancelled requests

  const action = {
    type: actionType + FAILURE,
    payload: {
      errors: response && response.data && response.data.errors,
      params,
      requestId,
      response,
      status: response.status || 0,
    },
  };
  dispatch(action);

  if (throwOnError) {
    throw action;
  } else {
    return action;
  }
};

const handleCancel = (dispatch, actionType, params, requestId, cancelTokenSource, throwOnError) => {
  const action = {
    type: actionType + CANCELLED,
    payload: {
      params,
      requestId,
      status: -1,
    },
  };
  dispatch(action);

  cancelTokenSource.cancel(); // This actually cancels the request with Axios

  if (throwOnError) {
    throw action;
  } else {
    return action;
  }
};

const makeRequest = (dispatch, actionType, url, params = {}, requestId = '@', method = 'get', throwOnError = true) => {
  dispatch({
    type: actionType + REQUEST,
    payload: { method, url, params, requestId },
  });

  const cancelTokenSource = axios.CancelToken.source();
  let requestActive = true;

  const config = getRequestConfig(method, url, params, cancelTokenSource);
  const request = axios
    .request(config)
    .then((response) => handleSuccess(response, dispatch, actionType, params, requestId))
    .catch((response) => handleFailure(response, dispatch, actionType, params, requestId, throwOnError))
    .finally(() => {
      requestActive = false;
    });
  return {
    request,
    cancel: () => {
      if (requestActive) {
        handleCancel(dispatch, actionType, params, requestId, cancelTokenSource, throwOnError);
        requestActive = false;
      }
    },
  };
};

export const apiRequest = (dispatch, actionType, url, params = {}, requestId = '@', method = 'get') => {
  return makeRequest(dispatch, actionType, url, params, requestId, method, false);
};

export const callbackApiRequest = (dispatch, actionType, url, params = {}, requestId = '@', method = 'get') => {
  return makeRequest(dispatch, actionType, url, params, requestId, method, true);
};

/**
 * Will return true if the given flags would mean a request could be fired
 * @param {Boolean} isReady - If everything needed is available, ready for a request
 * @param {Boolean} isLoading - IF a request is already in progress
 * @param {Boolean} isLoaded - If the resource is already available
 * @param {Boolean} hasFailed - The this request has failed before
 * @param {Boolean} force - Allow re-sending of request if it has failed in the past
 */
export const checkRequestNeeded = (isReady, isLoading, isLoaded, hasFailed, force) => {
  return isReady && !isLoading && !isLoaded && (!hasFailed || force);
};
