import { useEffect, useMemo, useState } from 'react';
import { config } from '../../../config';

// Move this to its own hook if used else where in future
const usePageScrollY = () => {
  const [scrollY, setScrollY] = useState(window.scrollY);
  
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return { scrollY };
};

export const useShowOriginalBackground = () => {
  const { scrollY } = usePageScrollY();
  const isHeaderStatic = config['header.static'];

  const showOriginalBackground = useMemo(() => {
    if (isHeaderStatic) return true;
    return scrollY > 97;
  }, [isHeaderStatic, scrollY]);

  return { showOriginalBackground };
};
