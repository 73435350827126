import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectChannel,
  selectDate,
  selectDuration,
  selectGuestsAdults,
  selectGuestsChildren,
  selectGuestsUnder5s,
  selectMonth,
  selectPetFriendly,
  selectRegion,
} from '../../redux/search/form/actions';

import { DDMMYYYYToDate } from '../../utils/date';
import { ynToBool } from '../../utils/utils';
import { dateValidator } from '../../utils/validation';

export const WithUrlParams = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const locationSearch = document.location.search;
  const [readyToDisplay, setReadyToDisplay] = useState(false);

  useEffect(() => {
    setReadyToDisplay(false);
    if (locationSearch) {
      const query = new URLSearchParams(search);
      // Holiday search
      if (query.has('channel')) selectChannel(dispatch, query.get('channel'));
      if (query.has('region')) selectRegion(dispatch, query.get('region'));
      if (query.has('nights')) selectDuration(dispatch, parseInt(query.get('nights')));
      if (query.has('date') && dateValidator(query.get('date'))) {
        const [, month, year] = query.get('date').split('/');
        selectMonth(dispatch, `${year}-${month}`);
        selectDate(dispatch, DDMMYYYYToDate(query.get('date')));
      }
      if (query.has('adults')) {
        const adults = parseInt(query.get('adults'));
        if (!isNaN(adults)) selectGuestsAdults(dispatch, adults);
      }
      if (query.has('children')) {
        const children = parseInt(query.get('children'));
        if (!isNaN(children)) selectGuestsChildren(dispatch, children);
      }
      if (query.has('infants')) {
        const infants = parseInt(query.get('infants'));
        if (!isNaN(infants)) selectGuestsUnder5s(dispatch, infants);
      }
      if (query.has('dogs')) {
        const dogs = ynToBool(query.get('dogs').toLowerCase());
        if (typeof dogs === 'boolean') selectPetFriendly(dispatch, dogs);
      }
    }

    setReadyToDisplay(true);
  }, [locationSearch]);

  if (!readyToDisplay) {
    return null;
  }

  return props.children;
};

export default WithUrlParams;
