import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const SelectInput = (props) => (
  <Form.Group className={props.groupClassName} controlId={props.controlId || props.name}>
    {props.label && (
      <Form.Label className={`${props.labelClassName} ${props.hideLabel ? 'invisible' : ''} `}>
        {props.label}
      </Form.Label>
    )}

    <Form.Control
      as="select"
      className={`${props.className} ${props.invalid ? 'is-invalid' : ''}`}
      name={props.name}
      defaultValue={props.defaultValue}
      value={props.value}
      {...props.attrs}
      onChange={props.onChange}
      custom
    >
      {props.children}
    </Form.Control>

    {props.invalid && <Form.Control.Feedback type="invalid">{props.validationMessage}</Form.Control.Feedback>}
  </Form.Group>
);

SelectInput.defaultProps = {
  groupClassName: '',
  labelClassName: '',
  className: 'col',
  useAriaLabel: false,
  attrs: {},
};

SelectInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectInput;
