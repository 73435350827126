import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

function InputNumber(props) {
  function inc() {
    if (props.onChange && props.value + 1 <= props.max) {
      props.onChange(props.value + 1);
    }
  }
  function dec() {
    if (props.onChange && props.value - 1 >= props.min) {
      props.onChange(props.value - 1);
    }
  }
  function change(event) {
    if (props.enableTyping) {
      const value = parseInt(event.target.value);
      if (isNaN(value)) return;
      if (props.onChange && value >= props.min && value <= props.max) {
        props.onChange(value);
      }
    }
  }

  return (
    <InputGroup>
      <InputGroup.Prepend onClick={dec} className="cursor-pointer" disabled={props.value <= props.min}>
        <InputGroup.Text>
          <strong>-</strong>
        </InputGroup.Text>
      </InputGroup.Prepend>

      <FormControl
        value={props.value}
        id={props.id}
        disabled={!props.enableTyping}
        onChange={change}
        className="text-center"
      />

      <InputGroup.Append onClick={inc} className="cursor-pointer" disabled={props.value >= props.max}>
        <InputGroup.Text>
          <strong>+</strong>
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

InputNumber.defaultProps = {
  value: 0,
  id: 'not-set',
  min: 0,
  max: 10,
  enableTyping: true,
};

InputNumber.propTypes = {
  value: PropTypes.number,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  enableTyping: PropTypes.bool,
};

export default InputNumber;
