export const BOOKING_STEPS = {
  EXTRAS: 'extras',
  DETAILS: 'details',
  PAYMENT: 'payment',
  PAYMENT_ACTION: 'payment-action',
  CONFIRMATION: 'confirmation',
};

export const HOLIDAY_TYPES = {
  EUROPEAN_HOLIDAY_PARKS: 'eurocaravan',
  BOATING_HOLIDAYS: 'boats',
  UK_LODGE_BREAKS: 'uklodge',
  UK_CAMPING_AND_TOURING_PARKS: 'uktouring',
  UK_COTTAGES: 'ukcottages',
  UK_HOLIDAY_PARKS: 'ukcaravan',
};

// Holiday type in here won't reset the date on duration change
export const CHANNELS_WHICH_DONT_RESET_DATE = [
  HOLIDAY_TYPES.BOATING_HOLIDAYS,
  HOLIDAY_TYPES.UK_CAMPING_AND_TOURING_PARKS,
  HOLIDAY_TYPES.UK_COTTAGES,
  HOLIDAY_TYPES.EUROPEAN_HOLIDAY_PARKS,
];

// Holiday types in here will filter available dates by holiday duration
export const CHANNELS_WITH_CUSTOM_FILTERING = [HOLIDAY_TYPES.UK_LODGE_BREAKS];

export const INTERNAL_LOCATIONS = ['/', 'https://www.breakfreeholidays', 'www.breakfreeholidays'];

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
