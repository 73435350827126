import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PasswordResetForm from '../../Forms/User/PasswordResetForm/PasswordResetForm';
import {
  usePasswordResetFormActions,
  usePasswordResetFormState,
} from '../../Forms/User/PasswordResetForm/usePasswordResetForm';

function PasswordResetFormView(props) {
  const {
    values,
    onChange,
    validate,
    isValid,
    errors,
    loggedIn,
    requestPasswordResetError,
    requestPasswordResetPending,
  } = usePasswordResetFormState();
  const { requestPasswordReset } = usePasswordResetFormActions();

  const [firstRun, setFirstRun] = useState(true); // Used to hide error messages if just opened
  useEffect(() => {
    setFirstRun(true);
    setSuccess(false);
  }, []);

  const [success, setSuccess] = useState(false);

  const handleResetPassword = (event) => {
    event.preventDefault();
    setSuccess(false);
    setFirstRun(false);
    validate();

    if (isValid()) {
      requestPasswordReset(values.email)
        .request.then(() => {
          setSuccess(true);
        })
        .catch(() => {
          /* Do nothing on failure, form will react to redux state */
        });
    }
  };

  return (
    <PasswordResetForm
      busy={requestPasswordResetPending}
      errors={firstRun ? undefined : errors}
      failure={firstRun ? undefined : requestPasswordResetError}
      loggedIn={loggedIn}
      onBack={props.onBack}
      onChange={onChange}
      onSubmit={handleResetPassword}
      success={firstRun ? undefined : success}
      values={values}
      handleClose={props.handleClose}
    />
  );
}

PasswordResetFormView.propTypes = {
  onBack: PropTypes.func,
};

export default PasswordResetFormView;
