import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';

export const InputCheckbox = (props) => {
  const handleChange = props.onChange ? () => props.onChange(!props.checked) : undefined;

  return (
    <InputGroup className={props.className}>
      <InputGroup.Prepend className="cursor-pointer" onClick={handleChange}>
        <InputGroup.Checkbox
          id={props.id}
          className="cursor-pointer"
          checked={props.checked}
          onChange={handleChange}
          aria-label={props.ariaLabel || props.label}
        />
      </InputGroup.Prepend>
      <label htmlFor={props.id} className="form-control h-auto" dangerouslySetInnerHTML={{ __html: props.label }} />
    </InputGroup>
  );
};

InputCheckbox.defaultProps = {
  id: 'not-set',
};

InputCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InputCheckbox;
