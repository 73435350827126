import React, { useState, useEffect, useMemo } from 'react';
import _debounce from 'lodash.debounce';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ColumnSlider from './ColumnSlider';
import { config } from '../../../config';
import A from '../A';
import { BREAKPOINTS } from '../../Constants';

function ColumnComponent({ heading, columns, background, parent }) {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width < BREAKPOINTS.sm;

  const noOfItems = columns.length;
  const imageClasses = ['column-image', 'fixed-image-height'];

  const regionPage = parent === 'opRegions';
  if (regionPage) imageClasses.push('img-max-height');

  const extraClasses = imageClasses.join(' ');

  const sliderComponent = (
    <Col className={`${regionPage ? 'd-block' : ''}`}>
      <ColumnSlider items={columns} parent={parent} extraClasses={extraClasses} />
    </Col>
  );

  const columnComponent = columns.map((c, index) => {
    const cardImage = (
      <div className="position-relative">
        <Card.Img className={extraClasses} src={c.image} alt={c.title} loading="lazy" />
        {c.overlay_text !== undefined && c.overlay_text.length > 0 && (
          <div className="column-text-overlay p-2 text-white text-uppercase w-100 font-weight-bold">
            {c.overlay_text}
          </div>
        )}
      </div>
    );

    const cardBody = (
      <Card.Body>
        <h3 className={`h5 m-0 text-${config['global.textHighlight']}`}>{c.title}</h3>
        <p className="text-darker m-0">{c.text}</p>
      </Card.Body>
    );

    return (
      <Col key={index} sm={isMobile ? 12 : 12 / noOfItems} className="d-flex">
        <Card className="flex-grow-1 home-column">
          {/^http/.test(c.url) ? (
            <A href={c.url} className="text-decoration-none d-block">
              {cardImage}
              {cardBody}
            </A>
          ) : (
            <Link to={c.url} className="text-decoration-none">
              {cardImage}
              {cardBody}
            </Link>
          )}
        </Card>
      </Col>
    );
  });

  useEffect(() => {
    const handleWindowResize = _debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener('resize', handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const getComponent = useMemo(() => {
    if (isMobile) return columnComponent;

    const max = regionPage ? 3 : 4;
    if (noOfItems > max || (noOfItems === 4 && width < BREAKPOINTS.lg) || (noOfItems === 3 && width < BREAKPOINTS.md)) {
      return sliderComponent;
    } else {
      return columnComponent;
    }
  }, [columnComponent, isMobile, noOfItems, regionPage, sliderComponent, width]);

  if (regionPage) return <Row>{getComponent}</Row>;

  return (
    <Container className={`p-0 py-3 bg-${background}`} fluid>
      <Container>
        <Row>
          {parent !== 'opRegions' && (
            <Col xs={12}>
              <h2
                className={`column-title text-center my-3 text-${background === 'primary' ? 'white' : 'darker'}`}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            </Col>
          )}
          {getComponent}
        </Row>
      </Container>
    </Container>
  );
}

ColumnComponent.defaultProps = {
  heading: 'No title set',
  columns: [
    {
      image: 'https://placeimg.com/640/320/nature',
      title: 'Column One Title',
      text: 'Some text goes here',
      url: '#',
    },
    {
      image: 'https://placeimg.com/640/320/nature',
      title: 'Column Two Title',
      text: 'Some text goes here',
      url: '#',
    },
  ],
};

export default ColumnComponent;
