import React, { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import t from '../../../text';
import { SearchBox } from '../SearchBox/SearchBox';
import { usePageWidth } from '../../hooks/usePageWidth';

const HEADER_HEIGHT = 80;
const SCROLL_PADDING = 15;

function Hero(props) {
  const { width } = usePageWidth();
  const buttonRef = useRef(null);

  const handleScrollToSearch = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Bootstrap's medium breakpoint starts at 768px and goes up to 991.98px
  const isMediumScreen = width > 768 && width < 992;

  return (
    <Container
      fluid
      className="masthead d-flex flex-column px-0"
      style={{
        backgroundImage:
          width < 768 && props.mobileHeroImage !== ''
            ? `url(${props.mobileHeroImage})`
            : props.heroImage !== ''
            ? `url(${props.heroImage})`
            : '',
      }}
    >
      {width < 768 && props.mobileHeroImage.includes('mp4') ? (
        <video muted autoPlay loop playsInline>
          <source src={props.mobileHeroImage} type="video/mp4" />
        </video>
      ) : props.heroImage.includes('mp4') ? (
        <video muted autoPlay loop playsInline>
          <source src={props.heroImage} type="video/mp4" />
        </video>
      ) : null}
      <Row className="flex-grow-1 mx-0">
        <Col className="d-flex flex-column align-items-center text-center text-white mt-4 mt-md-3">
          {props.headingImage ? (
            <img src={props.headingImage} className="heading-img" alt={props.heading} width="90%" />
          ) : (
            <h1 className="text-white mt-2" data-mh-lead>
              {props.heading}
            </h1>
          )}
          <p className="text-white mb-0 mx-auto" data-mh-sub>
            {props.subheading}
          </p>
          <Button
            className="d-md-none mt-auto mb-4 mx-auto px-5"
            style={{ scrollMarginTop: HEADER_HEIGHT + SCROLL_PADDING }}
            variant="secondary"
            ref={buttonRef}
            onClick={handleScrollToSearch}
          >
            {t('search.searchNow')}
          </Button>
        </Col>
      </Row>
      <Row className="d-none d-md-block align-self-end bg-primary w-100 mx-0">
        <Col xs="12" className="py-4 text-white">
          <SearchBox isHeroPage={isMediumScreen} includeEmail isHomePage />
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
