import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from '../../../text';
import { adultsSelector, childrenSelector, paramsSelector, under5sSelector } from '../../redux/search/form/selectors';
import { ChannelSelect } from './ChannelSelect';
import { DateDurationSelect } from './DateDurationSelect';
import { GuestsSelect } from './GuestsSelect';
import { RegionSelect } from './RegionSelect';
import { useHandleSearch } from './useHandleSearch';
import { useNewsletter } from '../Footer/NewsletterContext';

export const NoPopupSearchBox = (props) => {
  const params = useSelector(paramsSelector);
  const adults = useSelector(adultsSelector);
  const children = useSelector(childrenSelector);
  const under5s = useSelector(under5sSelector);

  const [email, setEmail] = useState('');

  const { handleSearch } = useHandleSearch();

  const formSubmit = (event) => handleSearch(event, email);

  const { hasSignedUpForDeals } = useNewsletter();

  return (
    <Container className="search-box" id="search-modal">
      <Form onSubmit={formSubmit}>
        <Row xs={1}>
          <Col lg={props.isInPopup ? 12 : 10}>
            <Row>
              <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                <ChannelSelect textStyle={props.text} />
              </Col>
              <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                <RegionSelect textStyle={props.text} />
              </Col>
              <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                <DateDurationSelect textStyle={props.text} />
              </Col>
              <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                <GuestsSelect textStyle={props.text} />
              </Col>
              {!hasSignedUpForDeals && (
                <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                  <Form.Group controlId="EmailAddress">
                    <Form.Label className={`text-${props.text}`}>{t('search.dealSignup')}</Form.Label>
                    <Form.Control
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Email address"
                      className="mt-md-0 toggle-popup"
                    />
                    <Form.Control.Feedback type="invalid">{t('footer.newsletter.invalidEmail')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Col>

          <Col>
            <Form.Label>&nbsp;</Form.Label>
            <Button
              block
              variant="secondary"
              type="submit"
              disabled={
                !params.channelId ||
                !params.regionId ||
                !params.arrivalDate ||
                !params.duration ||
                adults + children + under5s === 0
              }
            >
              {t('search.search')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

NoPopupSearchBox.defaultProps = {
  text: 'white',
  bg: 'dark',
  isInPopup: false,
};
