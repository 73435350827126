import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import t from '../../../text';
import { useSubmitAndCloseDropdown } from '../../hooks/useSubmitAndCloseDropdown';
import {
  DDMMYYYYToDate,
  getDayAndMonthNameFromDate,
  getDayNameFromDate,
  getMonthYearFromDate,
  getShortDateFormat,
} from '../../utils/date';

import { useDispatch, useSelector } from 'react-redux';
import { CHANNELS_WITH_CUSTOM_FILTERING, HOLIDAY_TYPES } from '../../Constants';
import { minPricesSelector, minPricesMonthsSelector } from '../../redux/data/cachedAvail/selectors';
import { selectDate, selectDuration, selectMonth } from '../../redux/search/form/actions';
import {
  arrivalDateSelector,
  channelIdSelector,
  durationSelector,
  monthSelector,
  partySizeSelector,
  petFriendlySelector,
  regionIdSelector,
} from '../../redux/search/form/selectors';
import { getFilteredRegionDates } from '../../utils/cachedAvail';
import { getNightsOptions, getUnavailableDaysDefault } from './DateInputHelpers';

const getDateAndDurationText = (arrivalDate, duration) => {
  if (arrivalDate && duration) {
    return t('search.dateNights', getShortDateFormat(new Date(arrivalDate)), duration);
  } else {
    return t('search.selectADate');
  }
};

export const DateDurationSelect = (props) => {
  const dispatch = useDispatch();
  const { submitAndCloseDropdown } = useSubmitAndCloseDropdown();

  const channelId = useSelector(channelIdSelector);
  const duration = parseInt(useSelector(durationSelector));
  const arrivalDate = useSelector(arrivalDateSelector);
  const arrivalDateDate = arrivalDate != null ? new Date(new Date(arrivalDate).setHours(0, 0, 0, 0)) : undefined;
  const month = useSelector(monthSelector);
  const _minPricesMonths = useSelector(minPricesMonthsSelector);
  const isLoadingDataSet = _minPricesMonths.length === 0;
  const _minPrices = useSelector(minPricesSelector);
  const partySize = useSelector(partySizeSelector);
  const selectedRegionId = useSelector(regionIdSelector);
  const petFriendly = useSelector(petFriendlySelector);

  const onDurationChange = (e) => selectDuration(dispatch, parseInt(e.target.value));
  const onMonthChange = (e) => selectMonth(dispatch, e.target.value);

  const [showDatePanel, setShowDatePanel] = useState(false);
  const handleDateToggle = () => setShowDatePanel(!showDatePanel);

  const handleDateChange = (date, modifiers, shouldCloseDropdown) => {
    if (modifiers.disabled) return;
    if (shouldCloseDropdown) {
      submitAndCloseDropdown(date, selectDate, setShowDatePanel);
    } else {
      selectDate(dispatch, date);
    }
  };

  const whichDataToUse = useMemo(() => {
    if (selectedRegionId === 'all-regions') return Object.values(_minPrices).flat();
    if (_minPrices[selectedRegionId]) return _minPrices[selectedRegionId];
    return [];
  }, [_minPrices, selectedRegionId]);

  const getFilteredValueDates = useCallback(
    (date) => {
      const [chosenYear, chosenMonth] = date?.split('-');
      if (whichDataToUse.length === 0) return [];
      return getFilteredRegionDates(whichDataToUse, chosenMonth, chosenYear, duration, petFriendly, partySize);
    },
    [duration, partySize, petFriendly, whichDataToUse],
  );

  const filteredHolidays = useMemo(() => {
    if (!month) return [];
    return getFilteredValueDates(month);
  }, [getFilteredValueDates, month]);

  // Only show month options which actually have some holidays
  const minPricesMonthsFiltered = useMemo(
    () => _minPricesMonths.filter((specificMonth) => getFilteredValueDates(specificMonth).length > 0),
    [_minPricesMonths, getFilteredValueDates],
  );

  // We have chosen a date combination on caravans that has no dates (high guests etc), reset date to undefined
  if (
    channelId === HOLIDAY_TYPES.UK_HOLIDAY_PARKS &&
    !isLoadingDataSet &&
    minPricesMonthsFiltered.length === 0 &&
    arrivalDate
  ) {
    selectDate(dispatch, undefined);
  }

  // This runs when filteredHolidays changes, it is responsible for:
  // picking the first available month if there are no dates in this month
  // picking the first date in a new month, if the current one doesn't exist
  useEffect(() => {
    const formattedArrivalDate = arrivalDateDate?.toLocaleDateString('en-GB').replace(/\//g, '/');
    const arrivalDateExistsInFilteredHolidays = filteredHolidays?.some(
      (dateObj) => dateObj.date === formattedArrivalDate,
    );

    if (isLoadingDataSet) return;

    if (filteredHolidays?.length === 0) {
      selectMonth(dispatch, minPricesMonthsFiltered[0]);
    } else if (!arrivalDateExistsInFilteredHolidays) {
      const dateSplit = filteredHolidays?.[0].date.split('/');
      const firstAvailableDateModified = new Date(
        new Date(dateSplit?.[2], +dateSplit?.[1] - 1, dateSplit?.[0]).setHours(0, 0, 0, 0),
      );
      handleDateChange(firstAvailableDateModified, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredHolidays]);

  // Return unavailable days, currently only for uk lodge breaks
  const disabledDaysObject = useMemo(() => {
    if (!CHANNELS_WITH_CUSTOM_FILTERING.includes(channelId)) return {};
    const unavailableDays = getUnavailableDaysDefault(duration);
    return { daysOfWeek: unavailableDays };
  }, [channelId, duration]);

  return (
    <Form.Group controlId="searchbox-date-duration">
      <Form.Label className={`text-${props.textStyle}`}>{t('search.durationAndArrivalDate')}</Form.Label>
      <Dropdown show={showDatePanel} onToggle={handleDateToggle} drop="down">
        <Dropdown.Toggle as="button" type="button" className="toggle-popup is-calendar" role="button" tabIndex="0">
          <span>{getDateAndDurationText(arrivalDate, parseInt(duration))}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="a-popup no-max-height p-0">
          <div className="bg-light border-bottom p-3">
            <h5>{t('search.stayingForHowLong')}</h5>
            <Form.Group controlId="searchbox-date-duration-nights" className="mb-0">
              <Form.Control as="select" value={duration} onChange={onDurationChange}>
                <option value="" disabled>
                  {t('search.numberOfNights')}
                </option>
                {getNightsOptions(channelId).map((val) => (
                  <option key={val} value={val}>
                    {t('search.xNights', val)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          {channelId === HOLIDAY_TYPES.UK_HOLIDAY_PARKS && minPricesMonthsFiltered.length > 0 && (
            <div className="p-3">
              <Form.Group controlId="Month">
                <h5>{t('search.month')}</h5>
                <Form.Control as="select" value={month} onChange={onMonthChange}>
                  <option value="" disabled>
                    {t('search.month')}
                  </option>
                  {minPricesMonthsFiltered.map((val) => (
                    <option key={val} value={val}>
                      {getMonthYearFromDate(val)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="Date" className="mb-0">
                <h5>{t('search.date')}</h5>
                <Form.Control
                  as="select"
                  value={arrivalDateDate ?? ''}
                  onChange={(e) => handleDateChange(e.target.value, false, false)}
                >
                  <option value="" disabled>
                    {t('search.selectADate')}
                  </option>
                  {filteredHolidays?.length > 0 &&
                    filteredHolidays.map((val, i) => {
                      const dayName = getDayNameFromDate(DDMMYYYYToDate(val.date), true);
                      const date = getDayAndMonthNameFromDate(DDMMYYYYToDate(val.date), true);
                      return (
                        <option key={i} value={new Date(DDMMYYYYToDate(val.date).setHours(0, 0, 0, 0))}>
                          {`${dayName} ${date} - from £${parseFloat(val.price)}`}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </div>
          )}
          {channelId === HOLIDAY_TYPES.UK_HOLIDAY_PARKS && minPricesMonthsFiltered.length === 0 && (
            <div className="p-3 pt-0">
              <p className="m-0">No available holidays for your current search criteria</p>
            </div>
          )}
          {channelId !== HOLIDAY_TYPES.UK_HOLIDAY_PARKS && (
            <div className="p-3">
              <h5>{t('search.selectAnArrivalDate')}</h5>
              <DayPicker
                fromMonth={new Date()}
                onDayClick={handleDateChange}
                initialMonth={arrivalDateDate}
                selectedDays={arrivalDateDate}
                disabledDays={[{ before: new Date() }, disabledDaysObject]}
              />
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

DateDurationSelect.defaultProps = {
  textStyle: 'white',
};

DateDurationSelect.propTypes = {
  textStyle: PropTypes.string,
};
