import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const regionsSelector = (state) => state.data.regions.regions;
export const channelRegionIdsSelector = (state) => state.data.regions.channelRegionIds;
export const operatorChannelRegionIdsSelector = (state) => state.data.regions.operatorChannelRegionIds;

// Utils to be used by other selectors
export const [getRegionsIsLoadingSelector, getRegionsHasFailedSelector, getRegionsErrorSelector] =
  getRequestStatus(GET_REGIONS);
export const getRegionsIsLoadedSelector = (state, channelId) => !!channelRegionIdsSelector(state)[channelId];

export const [
  getOperatorRegionsIsLoadingSelector,
  getOperatorRegionsHasFailedSelector,
  getOperatorRegionsErrorSelector,
] = getRequestStatus(GET_OPERATOR_REGIONS);
export const getOperatorRegionsIsLoadedSelector = (state, channelId, operatorId) => {
  return !!operatorChannelRegionIdsSelector(state)[operatorId]?.[channelId];
};
