import TagManager from 'react-gtm-module';
import { default as ReactGA4 } from 'react-ga4';

/**
 * Makes a best-effort attempt to produce 'opName - ParkName' (removing duplicated operator names within park names)
 * @param {string} opName - The operator name
 * @param {string} parkName - The park name
 */
export function getOperatorParkNameString(opName, parkName) {
  const parts = parkName.split(opName);
  return `${opName} - ${parts.length === 2 ? parts[1].trim() : parkName}`;
}

export function initGTM(siteCode) {
  TagManager.initialize({
    gtmId: siteCode,
  });
}

export function tagSaleCompleted(bookingId, amountPaid, unitTypeId, operatorId, regionId, parkName, channelId) {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: {
        purchase: {
          actionField: {
            id: bookingId, // 'the booking reference' - Transaction ID. Required for purchases and refunds
            affiliation: 'BreakFree Holidays Booking', // (static)
            revenue: amountPaid, // 'Booking cost' - Total transaction value (incl. tax and shipping)
          },
          products: [
            {
              // List of productFieldObjects.
              name: unitTypeId, // 'accommodation unit name' - Name or ID is required
              id: bookingId, // 'booking reference'
              price: amountPaid, // 'booking cost'
              brand: `${operatorId} - ${regionId}`, // 'operator - region'
              category: parkName, //'park name'
              variant: channelId, //'Channel Type'
              quantity: 1,
            },
          ],
        },
      },
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event: 'gtmBFH.saleCompleted',
    },
  });

  ReactGA4.gtag('event', 'purchase', {
    transaction_id: bookingId,
    value: amountPaid,
    currency: 'GBP',
    items: [
      {
        item_id: parkName,
        item_name: parkName,
        item_brand: regionId,
        price: amountPaid,
        quantity: 1,
        google_business_vertical: 'retail',
        id: bookingId,
      },
    ],
  });
}
