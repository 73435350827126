import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from '../../redux/user/actions';
import { loggedInSelector } from '../../redux/user/selectors';
import { config } from '../../../config';
import { HeaderBar } from './HeaderBar';
import LoginModal from '../Login/LoginModal';
import { HeaderNav } from './HeaderNav';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [hasMenuData, setHasMenuData] = useState(false);
  const [menuData, setMenuData] = useState();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const _loggedIn = useSelector(loggedInSelector);
  const handleHideLoginForm = () => setShowLoginForm(false);
  const handleLogout = () => logout(dispatch);
  const handleShowLoginForm = () => setShowLoginForm(true);
  const menuJSON = '/json-files/' + config['header.megaMenu'];

  useEffect(() => {
    // Fetch menu json
    const controller = new AbortController();
    fetch(menuJSON, { signal: controller.signal })
      .then((response) => response.json())
      .then((data) => {
        setMenuData(data);
      })
      .catch((error) => {
        console.error('Error: ' + error.message);
      })
      .finally(() => {
        setHasMenuData(true);
      });
    return () => {
      controller.abort();
    };
  }, [menuJSON]);

  if (!hasMenuData || !menuData) return null;
  
  return (
    <>
      <HeaderBar loggedIn={_loggedIn} onLogout={handleLogout} onShowLogin={handleShowLoginForm} />
      {!location.pathname.includes('/booking/') && (
        <HeaderNav
          loggedIn={_loggedIn}
          onLogout={handleLogout}
          onShowLogin={handleShowLoginForm}
          menuData={menuData}
        />
      )}
      <LoginModal show={showLoginForm} onClose={handleHideLoginForm} />
    </>
  );
}

export default Header;
