import React from 'react';
import { text as defaultText } from '../../base/defaultText';
import { getTranslation } from '../../base/utils/i18n';
import A from '../../base/components/A';

const text = {
  ...defaultText,
  'footer.nav.companyName': 'Breakfree Holidays',
  'footer.nav.tagline':
    'Breakfree Holidays is registered in England No. 05753643 | Copyright © Breakfree Holidays. All rights reserved.',
  'header.cs.tel': '0344 848 4534',
  'footer.newsletter.title': 'Sign up to our newsletter for a chance to win a £1000 holiday',
  'modal.newsletter.win': 'win a £1000',
  'modal.newsletter.title':
    'Sign up and save on your next holiday with offers sent straight to your inbox, as well as having the chance to win holidays!',
  'modal.newsletter.confirmTerms': (termsAndConditionsUrl, privacyPolicyUrl) => (
    <>
      By clicking the sign up button you agree to our <A href={termsAndConditionsUrl}>Terms of Use</A> and{' '}
      <A href={privacyPolicyUrl}>Privacy Policy</A>.
    </>
  ),
};

/**
 * Fetches a text string from the available text pack
 * @param {string} key - The key of the text string to retrieve
 * @param  {...any} params - Any parameters to pass to your string function. Optional.
 */
export const t = (key, ...params) => getTranslation(key, text, params);
export default t;
