import React, { Suspense, lazy, useEffect } from 'react';
import ReactGa from 'react-ga';
import { default as ReactGA4 } from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { config } from '../config';

// Redux store
import { store } from './redux/store';

// Stylesheets
import '../skin.scss';
import './assets/scss/libs.scss';

// Page routes
import Routes from './Routes';

// Components
import Header from './components/Header/Header';
import WithInit from './components/HOC/WithInit';
import WithUrlParams from './components/HOC/WithUrlParams';
import OnRouteChange from './components/OnRouteChange';
import { NewsletterProvider } from './components/Footer/NewsletterContext';

function App() {
  const Footer = lazy(() => import('../base/components/Footer/Footer'));

  useEffect(() => {
    const awin = new URLSearchParams(window.location.search).get('awc');
    if (awin !== null && awin.length > 0) {
      fetch(`${config['api.url']}/api/awin?awc=${awin}`)
        .then((response) => response.json())
        .then((data) => console.info(data));
    }
  }, []);

  useEffect(() => {
    ReactGa.initialize(config['google.ua']);

    const G_MEASUREMENT_ID = 'G-Z8MWV44F57';
    ReactGA4.initialize(G_MEASUREMENT_ID);
  }, []);

  return (
    <Provider store={store}>
      <div className="App">
        <Helmet>
          <title>{config['global.brandName']}</title>
          <meta name="description" content={config['global.brandName']} />
          <meta name="theme-color" content="#008f68" />
          {config['awin.enable'] && (
            <script
              defer="defer"
              src={`https://www.dwin1.com/${config['awin.trackingId']}.js`}
              type="text/javascript"
            />
          )}
          <link rel="canonical" href="https://www.breakfreeholidays.co.uk/" />
        </Helmet>
        <Router>
          <OnRouteChange />
          <Header />
          <WithInit>
            <WithUrlParams>
              <NewsletterProvider>
                <Suspense fallback={<div />}>
                  <main>
                    <Routes />
                  </main>
                  <Footer />
                </Suspense>
              </NewsletterProvider>
            </WithUrlParams>
          </WithInit>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
