import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import A from '../A';

export const HeaderNavLink = ({ label, href, external, onClick }) => {
  if (external) {
    return (
      <A href={href} className="nav-link" onClick={onClick}>
        {label}
      </A>
    );
  }
  return (
    <NavLink to={href} exact className="nav-link pl-3 mt-0 mt-lg-3" activeClassName="active" onClick={onClick}>
      {label}
    </NavLink>
  );
};

HeaderNavLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HeaderNavLink;
