import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../text';
import {
  selectGuestsAdults,
  selectGuestsChildren,
  selectGuestsUnder5s,
  selectPetFriendly,
} from '../../redux/search/form/actions';
import {
  adultsSelector,
  channelIdSelector,
  childrenSelector,
  petFriendlySelector,
  under5sSelector,
} from '../../redux/search/form/selectors';
import { shouldItPluralize } from '../../utils/utils';
import InputNumber from '../FormElements/InputNumber';
import Icon from '../Icon';

const MAX_GUESTS = 10;

export const GuestsSelect = ({ textStyle }) => {
  const dispatch = useDispatch();
  const adults = useSelector(adultsSelector);
  const children = useSelector(childrenSelector);
  const under5s = useSelector(under5sSelector);
  const petFriendly = useSelector(petFriendlySelector);
  const selectedChannelId = useSelector(channelIdSelector);

  // The site has to filter a lot of data when any of these change
  // So we set these into temp state and send them to redux when the dropdown closes
  // This is to prevent site stalling on each guest change
  const [adultsUI, setAdultsUI] = useState(adults);
  const [childrenUI, setChildrenUI] = useState(children);
  const [under5sUI, setUnder5sUI] = useState(under5s);
  const [petFriendlyUI, setPetFriendlyUI] = useState(petFriendly);

  const onAdultsChange = (num) => selectGuestsAdults(dispatch, num);
  const onChildrenChange = (num) => selectGuestsChildren(dispatch, num);
  const onUnder5sChange = (num) => selectGuestsUnder5s(dispatch, num);
  const onPetFriendlyChange = (bool) => selectPetFriendly(dispatch, bool);

  const guestTotal = adultsUI + childrenUI + under5sUI;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      onCloseFunction();
    }
  };

  // When the dropdown closes update each redux guest state,
  // but for performance check each has changed before doing so
  const onCloseFunction = () => {
    if (adultsUI !== adults) onAdultsChange(adultsUI);
    if (childrenUI !== children) onChildrenChange(childrenUI);
    if (under5sUI !== under5s) onUnder5sChange(under5sUI);
    if (petFriendlyUI !== petFriendly) onPetFriendlyChange(petFriendlyUI);
  };

  return (
    <Form.Group>
      <Form.Label className={`text-${textStyle}`}>
        <span>{t('search.yourParty')}</span>
      </Form.Label>
      <Dropdown onToggle={handleToggle} show={isOpen}>
        <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown" role="button" tabIndex="0">
          <span>{guestTotal === 0 ? 'Add guests' : `${guestTotal} ${shouldItPluralize('guest', guestTotal)}`}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="a-popup" onMouseLeave={onCloseFunction}>
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.adults')}</p>
            </Col>
            <Col xs={6}>
              <InputNumber
                id="searchbox-guests-adults"
                value={adultsUI}
                min={selectedChannelId === 'boats' ? 2 : 1}
                max={MAX_GUESTS - (childrenUI + under5sUI)}
                onChange={(num) => setAdultsUI(num)}
                enableTyping={false}
              />
            </Col>
            {selectedChannelId === 'boats' && (
              <Col>
                <Icon icon="attention" size="text-sm" className="mr-1" />
                <small>Min. 2 adults for Boating holidays.</small>
              </Col>
            )}
          </Row>
          <Dropdown.Divider />
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.children')}</p>
            </Col>
            <Col xs={6}>
              <InputNumber
                id="searchbox-guests-children"
                value={childrenUI}
                max={MAX_GUESTS - (adultsUI + under5sUI)}
                onChange={(num) => setChildrenUI(num)}
                enableTyping={false}
              />
            </Col>
          </Row>
          <Dropdown.Divider />
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.infants')}</p>
            </Col>
            <Col xs={6}>
              <InputNumber
                id="searchbox-guests-under5s"
                value={under5sUI}
                max={MAX_GUESTS - (adultsUI + childrenUI)}
                onChange={(num) => setUnder5sUI(num)}
                enableTyping={false}
              />
            </Col>
          </Row>
          {selectedChannelId !== 'eurocaravan' && (
            <>
              <Dropdown.Divider />
              <Row className="p-3 d-flex align-items-center">
                <Col xs={9}>
                  <label
                    htmlFor="petFriendly"
                    className="pet-friendly my-0 cursor-pointer text-darker font-weight-normal"
                  >
                    {t('search.showMePetFriendly')}
                  </label>
                </Col>
                <input
                  type="checkbox"
                  name="petFriendly"
                  id="petFriendly"
                  className="cursor-pointer mr-3 mr-md-0"
                  checked={petFriendlyUI}
                  onChange={(event) => setPetFriendlyUI(event.target.checked)}
                />
              </Row>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

GuestsSelect.defaultProps = {
  textStyle: 'white',
};

GuestsSelect.propTypes = {
  textStyle: PropTypes.string,
  pet: PropTypes.string,
  onPetChange: PropTypes.number,
};
