import moment from 'moment-timezone';

/**
 * Takes an array of objects and converts it to an object
 * @param {array} arr - The source array
 * @param {*} key - The property of each source object to use as the destination object key (E.G: you might use the 'id' property for this)
 * @param {*} valueKey - Optional. The property of each source object to use as the value of each key in the output object. If omitted, the entire source object will be used as the value.
 */
export const arrayToObject = (arr, key, valueKey) => {
  const output = {};
  arr.forEach((item) => {
    output[item[key]] = valueKey ? item[valueKey] : item;
  });
  return output;
};

/**
 * Takes a boolean input and converts it to 'y' or 'n'. Any other type of input will just be returned (useful for undefined).
 * @param {bool} input - input to convert
 */
export const booleanToYesNo = (input) => {
  if (input === true) {
    return 'y';
  }
  if (input === false) {
    return 'n';
  }
  return input;
};

/**
 * Return true if there is only 1 holiday, otherwise returns false
 * @param {number} dateMatch - number of date-match holidays (can be null / undefined)
 * @param {number} altDate - number of alternative date holidays (can be null / undefined)
 */
export const isSingleResult = (dateMatch, altDate) => {
  const evaluateValue = parseInt(dateMatch, 10) || parseInt(altDate, 10);
  return evaluateValue === 1;
};

/**
 * Takes 'y' or 'n' and coverts it to boolean. Any other type of input will just be returned (useful for undefined).
 * @param {bool} input - input to convert
 */
export const ynToBool = (input) => {
  if (input === 'y') {
    return true;
  }
  if (input === 'n') {
    return false;
  }
  return input;
};

/**
 * Takes a channelid and returns the correct type of accommodation for that channel.
 * Channelid's include ukcaravan, eurocaravan, uklodge, uktouring, boats and ukcottages.
 * @param {string} channel - channel to convert
 */
export const parkMatcher = (channel) => {
  switch (channel) {
    case 'boats':
      return 'Route';
    case 'ukcottages':
      return 'Cottage';
    default:
      return 'Park';
  }
};

/**
 * Takes a word and an amount and returns the word with relevant pluralization.
 * If count is 1, return word, else add an 's' to the word.
 * @param {string} word - word to convert
 * @param {number} count - amount of the word
 */
export const shouldItPluralize = (word, count) => {
  return count === 1 ? word : word + 's';
};

/**
 * Takes a word and a boolean and returns the word with relevant pluralization.
 * If boolean is true, return word, else add an 's' to the word.
 * @param {string} word - word to convert
 * @param {boolean} boolean - should it be singular
 */
export const forceSingular = (word, boolean) => {
  return boolean ? word : word + 's';
};

/**
 * Takes a channelid and returns the correct type of accommodationType for that channel.
 * Channelid's include boats, ukcottages, ukcaravan, uklodge and uktouring.
 * @param {string} channel - channel name
 */
export const accommodationType = (channel) => {
  switch (channel) {
    case 'boats':
      return 'Boating Holidays';
    case 'ukcottages':
      return 'Cottages';
    default:
      return 'Holiday Parks';
  }
};

/**
 * Creates an array of numbers from min to max, inclusive.
 * i.e. arrayFromXtoY(6, 9) would return [6, 7, 8, 9].
 * @param {number} min - array start
 * @param {number} max - array end
 */
export const arrayFromXtoY = (min, max) => {
  return [...new Array(max - min + 1)].map((_, i) => i + min);
};

/**
 * Sorts an array of objects based on a specific order and sorts based on a specific prop.
 * Pushes rogue values to the end of the array
 * @param {number} data - array of objects
 * @param {number} sortOrder - the order they should be sorted
 * @param {string} sortBy - the key on each object to sort by
 */
export const sortBasedOnProp = (data, sortOrder, sortBy) => {
  return data?.sort((a,b) => {
    const indexA = sortOrder.indexOf(a[sortBy]);
    const indexB = sortOrder.indexOf(b[sortBy]);

    // Use a very high index for items not found in EXTRAS_ORDER so we push them to the bottom
    const safeIndexA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
    const safeIndexB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

    return safeIndexA - safeIndexB;
  })
}

export function getAdjustedDateForBST(inputDate) {
  // Create a moment object in Europe/London timezone
  const londonTime = moment.tz(inputDate, 'Europe/London');
  
  // Check if the date is in BST
  if (londonTime.isDST()) {
    // Subtract one hour
    londonTime.subtract(1, 'hour');
  }
  
  return londonTime.toDate();
}