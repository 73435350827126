import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { regionIdSelector, requestParamsSelector } from '../redux/search/form/selectors';

export const useUpdateRoute = () => {
  const history = useHistory();

  const requestParams = useSelector(requestParamsSelector);
  const selectedRegionId = useSelector(regionIdSelector);

  const updateRoute = () => {
    const searchQuery = Object.keys(requestParams)
    .filter(
      (key) =>
        typeof requestParams[key] !== 'undefined' && key !== 'partysize',
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(requestParams[key])}`)
    .join('&');

    if (selectedRegionId === 'all-regions') {
      history.push({ pathname: '/results/all-regions', search: searchQuery });
    } else {
      history.push({ pathname: '/results', search: searchQuery });
    }
  };

  return { updateRoute };
};
